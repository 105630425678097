import React, {useState, useEffect, useRef} from 'react';
import "./DumbbellAddOption.scss";
import DumbbellDrawerHeader from "../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {
    addTraineeApi, createOption_api,
    createPackage_api,
    createProduct_api,
    createSubscriptiont_api, editOption_api,
    editPackage_api,
    editProduct_api,
    editSubscription_api,
    getLib_api,
    payMySelfPlanInvoiceApi,
    sendRequestPlanInvoiceApi,
    trainerThumbUploadApi,
    uploadProductImage,
    uploadSubscriptionImage
} from "../Services/Services";
import {message, Spin} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {checkFileSize, englishNumber, numberWithCommas, validation} from "../Services/utils";
import plusBlueIcon from "../assets/dumbbell_image/plus-blue-icon.png";
import trashWhiteIcon from "../assets/dumbbell_image/trash_white_icon.png";
import {useDispatchUploadLoading} from "../Hook/useDispatchUploadLoading";
import {Setup} from "../Services/Setup";

function DumbbellAddOption({handleDoneSendRequest,edit,traineeId}){


    //products
    let [fa_name,set_fa_name] = useState("")
    let [en_name,set_en_name] = useState("")
    let [title,set_title] = useState("")
    let [price,set_price] = useState("")
    let [discount,set_discount] = useState("")
    let [label,set_label] = useState("")
    let [stock,set_stock] = useState("")
    let [image,set_image] = useState("")
    let [imagePrev,set_imagePrev] = useState("")
    let [showInRegal,set_showInRegal] = useState(false)
    let [status,set_status] = useState(false)
    let [orderInRegal,set_orderInRegal] = useState(1)
    let [productDescription,set_productDescription] = useState("")
    let [ownerDescription,set_ownerDescription] = useState("")

    let [spinPage,set_spinPage] = useState(false)
    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)

    const setUploadLoading = useDispatchUploadLoading()

    useEffect(()=>{
        if(edit?._id){
            fillForms()
        }else {
            resetForm()
        }
    },[edit])



    const fillForms = ()=>{
            set_title(edit.title)
            set_status(edit.status==="active"?true:false)
            set_orderInRegal(edit.order_in_regal)

    }

    const resetForm =()=>{
        set_title("")
        set_fa_name("")
        set_en_name("")
        set_price("")
        set_discount("")
        set_stock("")
        set_productDescription("")
        set_descriptions([])
        set_ownerDescription("")
        set_showInRegal(false)
        set_status(false)
        set_orderInRegal(1)
        set_image("")
    }


    const editPackage = async ()=>{



        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }



        let obj ={
            title:title,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
        }

        try{
            set_spinPage(true)
            await editOption_api(obj,edit._id)
            set_spinPage(false)
            resetForm()
            message.success({
                content: "خدمت با موفقیت ویرایش شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ویرایش خدمت بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const sendInvoice = async ()=>{



        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title:title,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
        }

        try{
            set_spinPage(true)
            await createOption_api(obj)
            set_spinPage(false)
            resetForm()
            message.success({
                content: "خدمت با موفقیت ایجاد شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            set_spinPage(false)
            console.log(e.response.status)
            if(e.response.data.message==="max_limit"){
                return message.error({
                    content: "شما خدمات بیشتری نمیتوانید اضافه نمایید.",
                    className: 'toast-class'
                });
            }
            return message.error({
                content: "مشکلی در ایجاد خدمت بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const calcTotal = ()=>{
        let c = price;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }

    },[thumbUploadProgress])

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickUpload = (num)=>{
        fileRef.current.click();
    }

    const [descriptionInput,set_descriptionInput] = useState("");
    const [descriptions,set_descriptions] = useState([])


    const handleDeleteDescription = (d)=>{
        set_descriptions(descriptions.filter(f=>f.id!==d.id))
    }


    const handleAddDescription = ()=>{
        if(descriptionInput.length<1)
            return
        if(descriptions.length+1>3)
            return;

        set_descriptions([...descriptions,{id:uuidv4(),text:descriptionInput}])
        set_descriptionInput("")
    }

    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد خدمت جدید"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={title}
                            onChange={e=>set_title(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : بدنسازی"
                            id=""/>
                        <span>نام</span>
                    </div>



                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_status(!status)}
                            type="checkbox" checked={status} name="" id=""/>
                        <span>فعال کردن اشتراک</span>
                    </div>


                        <div className="dumbbell-add-trainee-form-item">
                            <input
                                pattern="[0-9]*"
                                value={orderInRegal}
                                onChange={e=>set_orderInRegal(englishNumber(e.target.value))}
                                type="tel" name="" id=""/>
                            <span>نوبت نمایش در رگال</span>
                        </div>





                    {!edit?._id?
                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ایجاد خدمت</span>
                    </div>:null}

                    {edit?._id?
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            editPackage()
                        }}>
                            <span>ویرایش خدمت</span>
                        </div>:null
                    }


                    <div className="padding-bottom"></div>
                </div>

            </Spin>






        </div>
    )
}

export default DumbbellAddOption;
