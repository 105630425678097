import "./EmployeeShop.scss"
import Swiper from "react-id-swiper";
import {Setup} from "../../Services/Setup";
import {numberWithCommas, setAntdDrawerWidth, validation} from "../../Services/utils";
import plusProductIcon from "../PageV2/assets/plusProductIcon.svg";
import React, {useRef, useState, useEffect} from "react";
import cartIcon from "./assets/cartIcon.svg"
import caretDownIcon from "./assets/caretDownIcon.svg"
import cartCheckedIcon from "./assets/cartCheckedIcon.png"
import {CloudUploadOutlined, LoadingOutlined, PictureOutlined} from "@ant-design/icons"

import {Drawer, message, Spin, Switch} from "antd";
import {
    clearCart,
    decCart,
    getItemCount, getPayableAmount, getTotalDiscount,
    getTotalItems,
    getTotalPrice,
    incCart,
    syncCart
} from "../../Services/cartService";
import {useCart} from "../../Hook/useCart";
import {useDispatchCart} from "../../Hook/useDispatchCart";
import {
    finalizeBasketApi,
    sendOrderOtpApi,
    trackOrderApi,
    uploadReceiptLandOrderApi,
    uploadReceiptLandPlanApi
} from "../../Services/Services";
import {Collapse} from "react-collapse";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import useWindowDimensions from "../../Hook/useWindowDimensions";

const ShopProductCard = ({showDetailDrawer, p, checkItemCount, handleAddItem, handleRemoveItem}) => {
    return (
        <div className="product-swiper-product-card">
            <div className="product-swiper-product-card-image" style={{width: '90%'}} onClick={e => {
                showDetailDrawer(p)
            }}>
                {p.image.length > 0 ?
                    <img src={Setup.filePoint + p.image} alt=""/> :
                    <div className="image-swiper-placeholder">
                        <PictureOutlined/>
                    </div>}
            </div>
            <div onClick={e => {
                showDetailDrawer(p)
            }}>
                <div className="product-swiper-product-card-price">
                    <span>{numberWithCommas(p.price - p.discount)}تومان</span>
                </div>

                <div className="product-swiper-product-card-discount">
                    {p.discount > 0 ? <span>{numberWithCommas(p.price)} تومان</span> : <span></span>}
                </div>


                <div className="product-swiper-product-card-name">
                    <span>{p.fa_name.length > 30 ? p.fa_name.substring(0, 30) + "..." : p.fa_name}</span>
                </div>
            </div>

            {p.stock > 0 && checkItemCount(p._id) < 1 ?
                <div className="product-swiper-product-card-add-btn clickable" onClick={e => {
                    handleAddItem(p)
                }}>
                    <img src={plusProductIcon} alt=""/>
                </div> : null
            }

            {p.stock < 1 ?
                <div className="out-of-stock">
                    <span>ناموجود</span>
                </div>
                : null}

            {p.stock > 0 && checkItemCount(p._id) > 0 ?
                <div className="add-remove-section">
                    <span className="add-section-btn clickable" onClick={e => handleAddItem(p)}>+</span>
                    <span className="count-section-btn">{checkItemCount(p._id)}</span>
                    <span className="remove-section-btn clickable" onClick={e => handleRemoveItem(p._id)}>-</span>
                </div>
                : null}

        </div>
    )
}

const EmployeeShop = ({page,employees, slug}) => {
    const MyRef = useRef(null);



    const paramsLink = {
        slidesPerView: 'auto',
        spaceBetween: 2,
        // rebuildOnUpdate : true,
        rtl: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }



    const goTo = (link)=>{
        window.open(link, '_blank');
    }




    return (
        <div className="online-shop-container" style={{marginTop:18}}>
            {employees.length > 0 ?
                <div className="swiper-page-wrapper">
                    <Swiper ref={MyRef}  {...paramsLink}>
                        {employees.map((p, i) => {
                            return (
                                // <SubscriptionCard p={p}/>
                                <div className="employee-card-swiper-product-card-wrapper">
                                    <div className="employee-card-swiper-product-card"  onClick={e=>goTo(p.page_link)}>
                                        <div className="employee-image">
                                            <img src={Setup.filePoint+p.image} alt=""/>
                                        </div>
                                        <div className="employee-info" style={{backgroundColor:page?.primary_color?page?.primary_color:""}}>
                                            <div className="employee-name">
                                                <span>{p.first_name +" "+p.last_name}</span>
                                            </div>
                                            <div className="employee-label">
                                                <span>{p.label}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="product-swiper-product-card-wrapper"></div>
                    </Swiper>

                </div> : null
            }


        </div>
    )
}

export default EmployeeShop;
