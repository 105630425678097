import React, {useState,useEffect} from 'react';
import "./DumbbellHeder.scss";
import menuIcon from  "../../assets/dumbbell_image/V2/menu_icon_v2.svg";
import bellIcon from  "../../assets/dumbbell_image/V2/bell_icon_v2.svg";
import backIcon from  "../../assets/dumbbell_image/V2/back_icon.svg";
import {Drawer} from "antd";
import DumbbellAddTrainee from "../../Pages/DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellTrainerMenu from "../../Pages/DumbbellTrainerMenu/DumbbellTrainerMenu";
import {useHistory} from "react-router";
import mobileIcon from "../../assets/dumbbell_image/V2/mobile_icon_v2.png";
import mailIcon from "../../assets/dumbbell_image/V2/mail_icon_v2.png";
import {getNotificationsCount_api} from "../../Services/Services";
import {setAntdDrawerWidth} from "../../Services/utils";


function DumbbellHeader({changeFilter=e=>{},user=null,back=false,notification=false,title=null}){

    const history = useHistory()

    //Add Trainee Drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)

    const showMenu = ()=>{
        set_visibleTraineeDrawer(!visibleTraineeDrawer)
    }

    const onMenuClose = () => {
        set_visibleTraineeDrawer(false);
    };


    //notification Drawer
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")


    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const [newPlanNotif,set_newPlanNotif] = useState(0)
    const [newOrderNotif,set_newOrderNotif] = useState(0)
    const [newSubsNotif,set_newSubsNotif] = useState(0)
    const [newTurnNotif,set_newTurnNotif] = useState(0)
    const [notDeliveredNotif,set_notDeliveredNotif] = useState(0)
    const [newMessageNotif,set_newMessageNotif] = useState(0)
    const [total,set_total] = useState(0)

    const [trainer,set_trainer] = useState({})

    const getNotificationsCount = async ()=>{
        try{
            const res = await getNotificationsCount_api()
            let data= res.data.data
            set_trainer(data.trainer)
            set_newPlanNotif(data.plan_new)
            set_newOrderNotif(data.order_new)
            set_newSubsNotif(data.subs_new)
            set_newTurnNotif(data.book_new)
            set_notDeliveredNotif(data.plan_not_delivered)
            set_newMessageNotif(data.message_new)
            let total = data.plan_new + data.book_new + data.plan_not_delivered + data.message_new + data.order_new + data.subs_new
            set_total(total)

        }catch (e) {
            console.log(e)
        }
    }

    const goToMyRequests = (t)=>{
        changeFilter('request',t)
        history.push("/my-requests",{filter:t})
        onBottomDrawerClose()
    }
    const goToMyTrainee = (t)=>{
        changeFilter('trainee',t)
        history.push("/my-trainee",{filter:t})
        onBottomDrawerClose()
    }
    const goBack = (t)=>{
        console.log("ssss :)")
        history.goBack()
        // onBottomDrawerClose()
    }

    useEffect(()=>{
        getNotificationsCount()
    },[])

    return(
        <div className="dumbbell-header-container" >

            {!back?<div className="dumbbell-header-item clickable" onClick={showBottomDrawer}>
                <img src={bellIcon} alt=""/>
                <span className="notification-badge">{total}</span>
            </div>:
                <div className="dumbbell-header-item clickable" onClick={goBack}>
                    <img src={backIcon} alt=""/>
                </div>
            }

            <div className="dumbbell-header-item">
                <span>{title?title:""}</span>
            </div>

            <div className="dumbbell-header-item clickable" onClick={showMenu}>
                <img src={menuIcon} alt=""/>
            </div>

            <Drawer destroyOnClose={true} bodyStyle={{padding:0,margin:0,backgroundColor:"white"}}  width={300} closable={false}  onClose={onMenuClose} visible={visibleTraineeDrawer}>
                <DumbbellTrainerMenu close={onMenuClose}/>
            </Drawer>



            <Drawer height={380}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">

                    {trainer?.services?.contains("subscription") ?
                    <div className="bottom-drawer-item clickable" onClick={e=>goToMyRequests("subscription")} >
                        {newSubsNotif>0?<span className="badge-count">{newSubsNotif}</span>:null}
                        <span className="label">درخواست اشتراک جدید</span>
                    </div>:null}

                    {trainer?.services?.contains("shop") ?
                    <div className="bottom-drawer-item clickable" onClick={e=>goToMyRequests("order")} >
                        {newOrderNotif>0?<span className="badge-count">{newOrderNotif}</span>:null}
                        <span className="label">درخواست سفارش جدید</span>
                    </div>:null}

                    {trainer?.services?.contains("plan") ?
                    <div className="bottom-drawer-item clickable" onClick={e=>goToMyRequests("new")} >
                        {newPlanNotif>0?<span className="badge-count">{newPlanNotif}</span>:null}
                        <span className="label">درخواست برنامه جدید</span>
                    </div>:null}

                    {trainer?.services?.contains("booking") ?
                    <div className="bottom-drawer-item clickable" onClick={e=>goToMyRequests("turn")}>
                        {newTurnNotif>0?<span className="badge-count">{newTurnNotif}</span>:null}
                        <span className="label">درخواست وقت جدید</span>
                    </div>:null}


                    {trainer?.services?.contains("plan") ?
                    <div className="bottom-drawer-item clickable" onClick={e=>goToMyRequests("not_delivered")}>
                        {notDeliveredNotif>0?<span className="badge-count">{notDeliveredNotif}</span>:null}
                        <span className="label">برنام های تحویل داده نشده</span>
                    </div>:null}

                    {trainer?.services?.contains("plan") ?
                    <div className="bottom-drawer-item clickable" onClick={e=>goToMyTrainee("unSeen")}>
                        {newMessageNotif>0?<span className="badge-count">{newMessageNotif}</span>:null}
                        <span className="label">پیام جدید</span>
                    </div>:null}
                    {/*<div className="bottom-drawer-item clickable" >*/}
                    {/*    <span className="badge-count">1</span>*/}
                    {/*    <span className="label">پیام استپ</span>*/}
                    {/*</div>*/}
                </div>
            </Drawer>

        </div>
    )
}

export default DumbbellHeader;
