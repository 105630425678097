import React, {useState, useEffect, useRef} from 'react';
import "./DumbbellAddEmployee.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {
    addTraineeApi, createEmployee_api,
    createPackage_api,
    createProduct_api,
    createSubscriptiont_api, editEmployee_api,
    editPackage_api,
    editProduct_api,
    editSubscription_api,
    getLib_api,
    payMySelfPlanInvoiceApi,
    sendRequestPlanInvoiceApi,
    trainerThumbUploadApi, uploadEmployeeImage,
    uploadProductImage,
    uploadSubscriptionImage
} from "../../Services/Services";
import {message, Spin} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {checkFileSize, englishNumber, numberWithCommas, validation} from "../../Services/utils";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import {Setup} from "../../Services/Setup";

function DumbbellAddEmployee({handleDoneSendRequest,edit,traineeId}){


    //products
    let [fa_name,set_fa_name] = useState("")
    let [en_name,set_en_name] = useState("")
    let [title,set_title] = useState("")

    let [first_name,set_first_name] = useState("")
    let [last_name,set_last_name] = useState("")
    let [mobile,set_mobile] = useState("")
    let [email,set_email] = useState("")
    let [page_link,set_page_link] = useState("")

    let [price,set_price] = useState("0")
    let [discount,set_discount] = useState("0")
    let [label,set_label] = useState("")
    let [stock,set_stock] = useState("0")
    let [image,set_image] = useState("")
    let [imagePrev,set_imagePrev] = useState("")
    let [showInRegal,set_showInRegal] = useState(false)
    let [status,set_status] = useState(false)
    let [orderInRegal,set_orderInRegal] = useState(1)
    let [productDescription,set_productDescription] = useState("")
    let [ownerDescription,set_ownerDescription] = useState("")

    let [spinPage,set_spinPage] = useState(false)
    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)

    const setUploadLoading = useDispatchUploadLoading()

    useEffect(()=>{
        if(edit?._id){
            fillForms()
        }else {
            resetForm()
        }
    },[edit])



    const fillForms = ()=>{
            set_first_name(edit.first_name)
            set_last_name(edit.last_name)
            set_page_link(edit.page_link)
            // set_en_name(edit.en_name)
            // set_title(edit.title)
            // set_price(edit.price.toString())
            set_label(edit.label)
            // set_discount(edit.discount.toString())
            // set_stock(edit.stock.toString())
            set_productDescription(edit.description)
            // set_ownerDescription(edit.owner_description)
            set_showInRegal(edit.show_in_regal)
            set_status(edit.status==="active"?true:false)
            set_orderInRegal(edit.order_in_regal)
            set_image(edit.image)
    }

    const resetForm =()=>{
        set_title("")
        set_fa_name("")
        set_en_name("")
        set_price("0")
        set_discount("0")
        set_stock("0")
        set_productDescription("")
        set_ownerDescription("")
        set_showInRegal(false)
        set_status(false)
        set_orderInRegal(1)
        set_image("")
    }


    const editPackage = async ()=>{
        let price_num= price.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let priceIsNum = /^\d+$/.test(price_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        // let estimateIsNum = /^\d+$/.test(estimate);



        if(first_name.length<2 || last_name.length<2){
            return message.error({
                content: "لطفا نام و نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            first_name:first_name,
            last_name:last_name,
            label:label,
            page_link:page_link,
            description:productDescription,
            show_in_regal:showInRegal,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
            image:image
        }


        try{
            set_spinPage(true)
            await editEmployee_api(obj,edit._id)
            if(imageFile){
                let productId = edit._id
                try{
                    let response = await uploadEmployeeImage(productId,imageFile,set_thumbUploadProgress);
                    set_imagePrev(null)
                    set_imageFile(null)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }catch (e) {
                    set_spinPage(false)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }
            }
            set_spinPage(false)
            resetForm()
            message.success({
                content: "پرسنل با موفقیت ویرایش شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ویرایش پرسنل بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const sendInvoice = async ()=>{
        let price_num= price.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let priceIsNum = /^\d+$/.test(price_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        // let estimateIsNum = /^\d+$/.test(estimate);


        if(first_name.length<2 || last_name.length<2){
            return message.error({
                content: "لطفا نام و نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }



        let obj ={
            first_name:first_name,
            last_name:last_name,
            label:label,
            page_link:page_link,
            description:productDescription,
            show_in_regal:showInRegal,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
            image:image
        }


        try{
            set_spinPage(true)
            let created = await createEmployee_api(obj)

            if(imageFile){
                let productId = created.data.data._id
                try{
                    let response = await uploadEmployeeImage(productId,imageFile,set_thumbUploadProgress);
                    set_imagePrev(null)
                    set_imageFile(null)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }catch (e) {
                    set_spinPage(false)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }
            }
            set_spinPage(false)
            resetForm()
            message.success({
                content: "پرسنل شما با موفقیت ایجاد شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            set_spinPage(false)
            console.log(e.response.status)
            if(e.response.data.message==="max_limit"){
                return message.error({
                    content: "شما پرسنل بیشتری نمیتوانید اضافه نمایید.",
                    className: 'toast-class'
                });
            }
            return message.error({
                content: "مشکلی در ایجاد پرسنل بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const calcTotal = ()=>{
        let c = price;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }

    },[thumbUploadProgress])

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickUpload = (num)=>{
        fileRef.current.click();
    }


    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد پرسنل جدید"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">


                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={first_name}
                            onChange={e=>set_first_name(e.target.value)}
                            type="text" name=""
                            placeholder=""
                            id=""/>
                        <span>نام</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={last_name}
                            onChange={e=>set_last_name(e.target.value)}
                            type="text" name=""
                            placeholder=""
                            id=""/>
                        <span>نام خانوادگی</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={page_link}
                            onChange={e=>set_page_link(e.target.value)}
                            type="text" name=""
                            placeholder=""
                            id=""/>
                        <span>لینک صفحه</span>
                    </div>



                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={label}
                            onChange={e=>set_label(e.target.value)}
                            type="text" name=""
                            placeholder="مربی بدنسازی"
                            id=""/>
                        <span>لیبل </span>
                    </div>



                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={productDescription}
                            onChange={e=>set_productDescription(e.target.value)}
                            type="text" name="" id=""/>
                        <span>توضیحات </span>
                    </div>

                    {/*<div className="dumbbell-add-trainee-form-item">*/}
                    {/*    <input*/}
                    {/*        value={ownerDescription}*/}
                    {/*        onChange={e=>set_ownerDescription(e.target.value)}*/}
                    {/*        type="text" name="" id=""/>*/}
                    {/*    <span>توضیحات اضافه</span>*/}
                    {/*</div>*/}




                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_showInRegal(!showInRegal)}
                            type="checkbox" checked={showInRegal} name="" id=""/>
                        <span>نمایش در رگال</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_status(!status)}
                            type="checkbox" checked={status} name="" id=""/>
                        <span>فعال کردن پرسنل</span>
                    </div>

                    {showInRegal?
                        <div className="dumbbell-add-trainee-form-item">
                            <input
                                pattern="[0-9]*"
                                value={orderInRegal}
                                onChange={e=>set_orderInRegal(englishNumber(e.target.value))}
                                type="tel" name="" id=""/>
                            <span>نوبت نمایش در رگال</span>
                        </div>:null
                    }


                    <div className="dumbbell-add-trainee-form-item">
                        {imagePrev || image?<span onClick={handleClickUpload} className="image-btn clickable">ویرایش تصویر</span>:null}
                        {!imagePrev && !image?<span onClick={handleClickUpload} className="image-btn clickable">آپلود تصویر</span>:null}
                        <span>تصویر</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <div className="product-add-image">
                            {!imagePrev && image? <img src={Setup.filePoint+image} alt=""/>:null}
                            {imagePrev? <img src={imagePrev} alt=""/>:null}
                        </div>
                    </div>


                    {/*<div className="total-section" style={{margin:0}}>*/}
                    {/*    <span className="total-section-label">{`مبلغ قابل پرداخت محصول`}</span>*/}
                    {/*    <span className="total-section-price">{calcTotal()} تومان </span>*/}
                    {/*</div>*/}



                    {!edit?._id?
                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ایجاد پرسنل</span>
                    </div>:null}

                    {edit?._id?
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            editPackage()
                        }}>
                            <span>ویرایش پرسنل</span>
                        </div>:null
                    }

                    <input type="file" ref={fileRef}
                           accept=".png, .jpg, .jpeg"
                           name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
                    {/*<div className="dumbbell-add-trainee-form-submit-pay-my-invoice" onClick={e=>{*/}
                    {/*    payMySelfInvoice()*/}
                    {/*}}>*/}
                    {/*    <span>خودم پرداخت میکنم</span>*/}
                    {/*</div>*/}
                    <div className="padding-bottom"></div>
                </div>

            </Spin>






        </div>
    )
}

export default DumbbellAddEmployee;
