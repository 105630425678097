import React, {useState, useEffect, useRef} from 'react';
import "./DumbbellAddSubscription.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {
    addTraineeApi,
    createPackage_api,
    createProduct_api,
    createSubscriptiont_api,
    editPackage_api,
    editProduct_api,
    editSubscription_api,
    getLib_api, listOption_api,
    payMySelfPlanInvoiceApi,
    sendRequestPlanInvoiceApi,
    trainerThumbUploadApi,
    uploadProductImage,
    uploadSubscriptionImage
} from "../../Services/Services";
import {message, Spin} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {checkFileSize, englishNumber, numberWithCommas, validation} from "../../Services/utils";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import {Setup} from "../../Services/Setup";

function DumbbellAddSubscription({handleDoneSendRequest,edit,traineeId}){


    //products
    let [fa_name,set_fa_name] = useState("")
    let [en_name,set_en_name] = useState("")
    let [title,set_title] = useState("")
    let [price,set_price] = useState("")
    let [options,set_options] = useState({})
    let [discount,set_discount] = useState("")
    let [label,set_label] = useState("")
    let [stock,set_stock] = useState("")
    let [image,set_image] = useState("")
    let [imagePrev,set_imagePrev] = useState("")
    let [showInRegal,set_showInRegal] = useState(false)
    let [status,set_status] = useState(false)
    let [orderInRegal,set_orderInRegal] = useState(1)
    let [productDescription,set_productDescription] = useState("")
    let [ownerDescription,set_ownerDescription] = useState("")

    let [spinPage,set_spinPage] = useState(false)
    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)

    const setUploadLoading = useDispatchUploadLoading()

    useEffect(()=>{
        getOptions()
        if(edit?._id){
            fillForms()
        }else {
            resetForm()
        }
    },[edit])

    const [optionsData,set_optionsData] = useState([])

    const getOptions = async ()=>{
        try{
            const result =  await listOption_api()
            set_optionsData(result.data.data)
        }catch (e) {
            console.log(e)
        }

    }

    const fillForms = ()=>{
            set_fa_name(edit.fa_name)
            set_en_name(edit.en_name)
            set_title(edit.title)
            set_options(edit?.options?.length>0?edit?.options[0]:{})
            set_price(edit.price.toString())
            set_label(edit.label)
            set_discount(edit.discount.toString())
            set_stock(edit.stock.toString())
            set_productDescription(edit.description)
            set_descriptions(edit.description)
            set_ownerDescription(edit.owner_description)
            set_showInRegal(edit.show_in_regal)
            set_status(edit.status==="active"?true:false)
            set_orderInRegal(edit.order_in_regal)
            set_image(edit.image)
    }

    const resetForm =()=>{
        set_title("")
        set_fa_name("")
        set_en_name("")
        set_price("")
        set_options({})
        set_discount("")
        set_stock("")
        set_productDescription("")
        set_descriptions([])
        set_ownerDescription("")
        set_showInRegal(false)
        set_status(false)
        set_orderInRegal(1)
        set_image("")
    }


    const editPackage = async ()=>{
        let price_num= price.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let priceIsNum = /^\d+$/.test(price_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let stockIsNum = /^\d+$/.test(stock);
        // let estimateIsNum = /^\d+$/.test(estimate);



        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }


        // if(fa_name.length<2){
        //     return message.error({
        //         content: "لطفا نام فارسی را وارد نمایید",
        //         className: 'toast-class'
        //     });
        // }


        if(price_num.length<1){
            return message.error({
                content: "لطفا قیمت را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!priceIsNum){
            return message.error({
                content: "لطفا قیمت را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(stock.length<1){
            return message.error({
                content: "لطفا موجودی را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!stockIsNum){
            return message.error({
                content: "لطفا موجودی را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        //check lt 1000
        let pr = parseInt(price_num)
        let ds = parseInt(discount_num)

        if(pr-ds<1000){
            return message.error({
                content: "مبلغ قابل پرداخت باید بیشتر از 1000 تومان باشد",
                className: 'toast-class'
            });
        }

        let obj ={
            title:title,
            price: parseInt(price_num),
            discount: parseInt(discount_num),
            stock:parseInt(stock),
            label:label,
            options:[options],
            description:descriptions,
            show_in_regal:showInRegal,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
            image:image
        }



        try{
            set_spinPage(true)
            await editSubscription_api(obj,edit._id)
            if(imageFile){
                let productId = edit._id
                try{
                    let response = await uploadSubscriptionImage(productId,imageFile,set_thumbUploadProgress);
                    set_imagePrev(null)
                    set_imageFile(null)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }catch (e) {
                    set_spinPage(false)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }
            }
            set_spinPage(false)
            resetForm()
            message.success({
                content: "اشتراک با موفقیت ویرایش شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ویرایش اشتراک بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const sendInvoice = async ()=>{
        let price_num= price.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let priceIsNum = /^\d+$/.test(price_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let stockIsNum = /^\d+$/.test(stock);
        // let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }


        // if(fa_name.length<2){
        //     return message.error({
        //         content: "لطفا نام فارسی را وارد نمایید",
        //         className: 'toast-class'
        //     });
        // }
        //
        //
        // if(price_num.length<1){
        //     return message.error({
        //         content: "لطفا قیمت را وارد نمایید",
        //         className: 'toast-class'
        //     });
        // }

        if(!priceIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(stock.length<1){
            return message.error({
                content: "لطفا موجودی را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!stockIsNum){
            return message.error({
                content: "لطفا موجودی را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        //check lt 1000
        let pr = parseInt(price_num)
        let ds = parseInt(discount_num)

        if(pr-ds<1000){
            return message.error({
                content: "مبلغ قابل پرداخت باید بیشتر از 1000 تومان باشد",
                className: 'toast-class'
            });
        }

        let obj ={
            title:title,
            price: parseInt(price_num),
            discount: parseInt(discount_num),
            stock:parseInt(stock),
            label:label,
            options:[options],
            description:descriptions,
            show_in_regal:showInRegal,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
            image:image
        }


        try{
            set_spinPage(true)
            let created = await createSubscriptiont_api(obj)

            if(imageFile){
                let productId = created.data.data._id
                try{
                    let response = await uploadSubscriptionImage(productId,imageFile,set_thumbUploadProgress);
                    set_imagePrev(null)
                    set_imageFile(null)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }catch (e) {
                    set_spinPage(false)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }
            }
            set_spinPage(false)
            resetForm()
            message.success({
                content: "اشتراک با موفقیت ایجاد شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            set_spinPage(false)
            console.log(e.response.status)
            if(e.response.data.message==="max_limit"){
                return message.error({
                    content: "شما اشتراک بیشتری نمیتوانید اضافه نمایید.",
                    className: 'toast-class'
                });
            }
            return message.error({
                content: "مشکلی در ایجاد اشتراک بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const calcTotal = ()=>{
        let c = price;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }

    },[thumbUploadProgress])

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickUpload = (num)=>{
        fileRef.current.click();
    }

    const [descriptionInput,set_descriptionInput] = useState("");
    const [descriptions,set_descriptions] = useState([])


    const handleDeleteDescription = (d)=>{
        set_descriptions(descriptions.filter(f=>f.id!==d.id))
    }


    const handleAddDescription = ()=>{
        if(descriptionInput.length<1)
            return
        if(descriptions.length+1>3)
            return;

        set_descriptions([...descriptions,{id:uuidv4(),text:descriptionInput}])
        set_descriptionInput("")
    }

    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد اشتراک جدید"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={title}
                            onChange={e=>set_title(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : اشتراک ۳ ماهه"
                            id=""/>
                        <span>عنوان</span>
                    </div>

                    {/*<div className="dumbbell-add-trainee-form-item">*/}
                    {/*    <input*/}
                    {/*        value={fa_name}*/}
                    {/*        onChange={e=>set_fa_name(e.target.value)}*/}
                    {/*        type="text" name=""*/}
                    {/*        placeholder="مثلا : مکمل کراتین"*/}
                    {/*        id=""/>*/}
                    {/*    <span>نام فارسی</span>*/}
                    {/*</div>*/}

                    {/*<div className="dumbbell-add-trainee-form-item">*/}
                    {/*    <input*/}
                    {/*        value={en_name}*/}
                    {/*        onChange={e=>set_en_name(e.target.value)}*/}
                    {/*        type="text" name=""*/}
                    {/*        placeholder="مثلا : cratine"*/}
                    {/*        id=""/>*/}
                    {/*    <span>نام انگلیسی</span>*/}
                    {/*</div>*/}

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={price}
                            onChange={e=>set_price(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>هزینه اشتراک  (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={discount}
                            onChange={e=>set_discount(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>تخفیف اشتراک (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={stock}
                            onChange={e=>set_stock(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>تعداد موجود</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={label}
                            onChange={e=>set_label(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : فقط تا 5 خرداد ماه"
                            id=""/>
                        <span>لیبل اشتراک</span>
                    </div>



                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={descriptionInput}
                            onKeyPress={e=>{if(e.key==="Enter"){handleAddDescription()}}}
                            onChange={e=>set_descriptionInput(e.target.value)}
                            type="text" name="" id=""/>
                        <img className="add-package-img" src={plusBlueIcon} alt="" onClick={e=>{
                            handleAddDescription()
                        }}/>
                        <span>توضیحات </span>
                    </div>

                    {descriptions.length>0?<div className="package-descriptions">
                        {descriptions.map(d=>{
                            return(
                                <div className="package-descriptions-row">
                                    <img src={trashWhiteIcon} className="package-bullet clickable" onClick={e=>{
                                        handleDeleteDescription(d)
                                    }}/>
                                    <span className="package-descriptions-row-text">{d.text}</span>
                                </div>
                            )
                        })}
                    </div>:null}


                    {/*<div className="dumbbell-add-trainee-form-item">*/}
                    {/*    <input*/}
                    {/*        value={productDescription}*/}
                    {/*        onChange={e=>set_productDescription(e.target.value)}*/}
                    {/*        type="text" name="" id=""/>*/}
                    {/*    <span>توضیحات اشتراک</span>*/}
                    {/*</div>*/}

                    {/*<div className="dumbbell-add-trainee-form-item">*/}
                    {/*    <input*/}
                    {/*        value={ownerDescription}*/}
                    {/*        onChange={e=>set_ownerDescription(e.target.value)}*/}
                    {/*        type="text" name="" id=""/>*/}
                    {/*    <span>توضیحات اضافه</span>*/}
                    {/*</div>*/}



                    <div className="dumbbell-add-trainee-form-item">
                        <select
                            value={options._id}
                            onChange={e=>{
                                let id= e.target.value
                                let find = optionsData.find(o=>o._id===id)
                                set_options(find)
                            }}
                            className="option-select" name="" id="">
                            {optionsData.map(o=>{
                                return(
                                    <option value={o._id}>{o.title}</option>
                                )
                            })}
                        </select>
                        <span>خدمت مرتبط</span>
                    </div>


                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_showInRegal(!showInRegal)}
                            type="checkbox" checked={showInRegal} name="" id=""/>
                        <span>نمایش در رگال</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_status(!status)}
                            type="checkbox" checked={status} name="" id=""/>
                        <span>فعال کردن اشتراک</span>
                    </div>

                    {showInRegal?
                        <div className="dumbbell-add-trainee-form-item">
                            <input
                                pattern="[0-9]*"
                                value={orderInRegal}
                                onChange={e=>set_orderInRegal(englishNumber(e.target.value))}
                                type="tel" name="" id=""/>
                            <span>نوبت نمایش در رگال</span>
                        </div>:null
                    }


                    <div className="dumbbell-add-trainee-form-item">
                        {imagePrev || image?<span onClick={handleClickUpload} className="image-btn clickable">ویرایش تصویر</span>:null}
                        {!imagePrev && !image?<span onClick={handleClickUpload} className="image-btn clickable">آپلود تصویر</span>:null}
                        <span>تصویر</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <div className="product-add-image">
                            {!imagePrev && image? <img src={Setup.filePoint+image} alt=""/>:null}
                            {imagePrev? <img src={imagePrev} alt=""/>:null}
                        </div>
                    </div>


                    <div className="total-section" style={{margin:0}}>
                        <span className="total-section-label">{`مبلغ قابل پرداخت اشتراک`}</span>
                        <span className="total-section-price">{calcTotal()} تومان </span>
                    </div>



                    {!edit?._id?
                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ایجاد اشتراک</span>
                    </div>:null}

                    {edit?._id?
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            editPackage()
                        }}>
                            <span>ویرایش اشتراک</span>
                        </div>:null
                    }

                    <input type="file" ref={fileRef}
                           accept=".png, .jpg, .jpeg"
                           name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
                    {/*<div className="dumbbell-add-trainee-form-submit-pay-my-invoice" onClick={e=>{*/}
                    {/*    payMySelfInvoice()*/}
                    {/*}}>*/}
                    {/*    <span>خودم پرداخت میکنم</span>*/}
                    {/*</div>*/}
                    <div className="padding-bottom"></div>
                </div>

            </Spin>






        </div>
    )
}

export default DumbbellAddSubscription;
