import React,{useEffect,useState} from "react"
import "./MyStep.scss"

import CheapIcon from "../../assets/dumbbell_image/V2/cheap_icon.png"
import GiftIcon from "../../assets/dumbbell_image/V2/gift_icon.svg"
import OrderIcon from "../../assets/dumbbell_image/V2/OrderIcon1.svg"
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import {convertMonthNumberToName, numberWithCommas, setAntdDrawerWidth, sleep} from "../../Services/utils";
import {
    buyRewardApi,
    checkoutDebt_api, checkoutForceDebt_api,
    getDebtApi,
    getNotificationsCount_api, getReportPage_api,
    getRewardApi,
    getTrainerProfileApi
} from "../../Services/Services";
import moment from "moment-jalaali";
import {Drawer, message, Spin} from "antd";

import MessageIcon from "../../assets/dumbbell_image/V2/message_menu_icon.svg";
import traineeIcon from "../../assets/dumbbell_image/V2/menu/trainee_menu_icon.svg";
import packageIcon from "../../assets/dumbbell_image/V2/menu/package_menu_icon.svg";
import bookingIcon from "../../assets/dumbbell_image/V2/menu/booking_menu_icon.svg";
import SubsIcon from "../../assets/dumbbell_image/V2/menu/subs_icon.svg";
import ReactApexCharts from "react-apexcharts";
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg"
import {useHistory} from "react-router";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

let startAt =null
let endAt =null

function MyStep(){

    const setLoader = useDispatchLoading()
    useEffect(()=>{

        initMyStep()
    },[])


    const initMyStep = async ()=>{
        setLoader(true)
        await getPageData()
        //calc Now date
        let date = new Date()
        let y = moment(date).format("jYYYY")
        let m = moment(date).format("jMM")

        let month = parseInt(m);
        let nextMonth = parseInt(m)+1;

        if(m==="12"){
            let next_year = parseInt(y)+1
            let startTime = y+"-12-01"
            let endTime = next_year+"-01-01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            set_selectedMonth(m)
            set_selectedYear(y)
            getReportPage()
        }else {
            if(month<10)
                month = "0"+month
            if(nextMonth<10)
                nextMonth = "0"+nextMonth

            let startTime = y+"-"+month+"-"+"01"
            let endTime = y+"-"+nextMonth+"-"+"01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            set_selectedMonth(m)
            set_selectedYear(y)
            getReportPage()
        }
    }

    const logout  =()=>{
        localStorage.clear()
        history.push("/login-trainer")
    }

    const [trainer,set_trainer] = useState(null)
    const [rewards,set_rewards] = useState(null)
    const [debt,set_debt] = useState(null)
    const [force_debt,set_force_debt] = useState(null)

    const [newPlanNotif,set_newPlanNotif] = useState(0)
    const [newOrderNotif,set_newOrderNotif] = useState(0)
    const [newSubsNotif,set_newSubsNotif] = useState(0)
    const [newTurnNotif,set_newTurnNotif] = useState(0)
    const [notDeliveredNotif,set_notDeliveredNotif] = useState(0)
    const [newMessageNotif,set_newMessageNotif] = useState(0)
    const [total,set_total] = useState(0)

    const getPageData = async ()=>{
        try{
            // const trainer_data = await getTrainerProfileApi()
            // set_trainer(trainer_data.data.data)
            const reward_data = await getRewardApi()
            set_rewards(reward_data.data.data)
            const debt_data = await getDebtApi()
            set_debt(debt_data.data.data.debt)
            set_force_debt(debt_data.data.data.debt_force)
            await getNotificationsCount()
            setLoader(false)
        }catch (e) {
            setLoader(false)
            console.log(e)
        }
    }


    const getNotificationsCount = async ()=>{
        try{
            const res = await getNotificationsCount_api()
            let data= res.data.data
            set_newPlanNotif(data.plan_new)
            set_newOrderNotif(data.order_new)
            set_newSubsNotif(data.subs_new)
            set_newTurnNotif(data.book_new)
            set_notDeliveredNotif(data.plan_not_delivered)
            set_newMessageNotif(data.message_new)
            let total = data.plan_new+data.book_new+data.plan_not_delivered+data.message_new
            set_total(total)

        }catch (e) {
            console.log(e)
        }
    }

    const [selectedReward,set_selectedReward] = useState(null)
    const handleClickOnReward = (r)=>{
        if(trainer?.point<r.amount){
            return message.error({
                content: "امتیاز شما برای خرید این کارت کافی نمی باشد",
                className: 'toast-class'
            });
        }
        set_selectedReward(r)
        showRewardBottomDrawer(true)

    }

    //Year Drawer
    const [visibleRewardBottomDrawer,set_visibleRewardBottomDrawer] = useState(false)

    const showRewardBottomDrawer = ()=>{
        set_visibleRewardBottomDrawer(true)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const onRewardBottomDrawerClose = () => {
        set_visibleRewardBottomDrawer(false)
    }

    const [debtSpin,set_debtSpin] = useState(false)

    const checkoutForceDebt = async (fd)=>{
        console.log("dddd : ",fd)
        if(fd<1){
            return message.error({
                content: "در حال حاضر بدهی برای شما وجود ندارد",
                className: 'toast-class'
            });
        }
        set_debtSpin(true)
        try{
            const response = await checkoutForceDebt_api()
            await getPageData()
            set_debtSpin(false)
            window.open(response.data.data.url, '_blank');
        }catch (e) {
            console.log(e)
            set_debtSpin(false)
            return message.error({
                content: "مشکل در تسویه بدهی پیش آمده",
                className: 'toast-class'
            });
        }
    }


    const checkoutDebt = async ()=>{
        console.log("debt : ",debt)
        if(debt<1){
            return message.error({
                content: "در حال حاضر بدهی برای شما وجود ندارد",
                className: 'toast-class'
            });
        }
        set_debtSpin(true)
        try{
            const response = await checkoutDebt_api()
            await getPageData()
            set_debtSpin(false)
            window.open(response.data.data.url, '_blank');
        }catch (e) {
            console.log(e)
            set_debtSpin(false)
            return message.error({
                content: "مشکل در تسویه بدهی پیش آمده",
                className: 'toast-class'
            });
        }
    }


    const [buySpin,set_buySpin] = useState(false)

    const handleCancelBuy = ()=>{
        set_visibleRewardBottomDrawer(false)
    }

    const handleAcceptBuy = async ()=>{
        set_buySpin(true)
        try{
            await buyRewardApi(selectedReward._id)
            await getPageData()
            set_buySpin(false)
            set_visibleRewardBottomDrawer(false)
            return message.success({
                content: "جایزه با موفقیت دریافت شد",
                className: 'toast-class'
            });
        }catch (e) {
            return message.error({
                content: "شما قادر به دریافت این جایزه نمیباشید",
                className: 'toast-class'
            });
            set_buySpin(false)
            console.log(e)
        }
    }

    const [series,set_series] = useState([
        {
            name: 'رزرو نوبت',
            data:  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },
        {
            name: 'خرید پکیج',
            data:  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },
        {
            name: 'بازدید',
            data:  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },
        {
            name: 'سفارش',
            data:  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },
        {
            name: 'اشتراک',
            data:  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },

    ])


    const [options,set_options] = useState( {
        chart: {
            toolbar: {
                show: false,
            },
            fontFamily: 'YekanRegular',
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        colors:['#f5d160','#f59b87','#87acf5','#e182e7','#7cd4c2'],
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            // type: 'datetime',
            categories: [
                1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
            ]
        }
    })

    const history = useHistory()

    const [buyPlan,set_buyPlan] = useState(0)
    const [buyBook,set_buyBook] = useState(0)
    const [buyOrder,set_buyOrder] = useState(0)
    const [buySubs,set_buySubs] = useState(0)
    const [visit,set_visit] = useState(0)

    const [visitChart,set_visitChart] = useState(null)
    const getReportPage = async ()=>{
        console.log(startAt,endAt)
        try{
            const resp = await getReportPage_api(startAt,endAt)
            let d = resp.data.data;
            set_buyPlan(d.plan_count)
            set_buyBook(d.book_count)
            set_visit(d.visit_count)
            set_buyOrder(d.order_count)
            set_buySubs(d.subs_count)


            let plan_x = new Array(31).fill(0)
            d.plans_chart.forEach(plan=>{
                let c= moment(plan._id,"YYYY-MM-DD").format('jYYYY-jMM-jDD')
                console.log(c)
                let day = c.split("-")[2]
                plan_x[parseInt(day)-1] = plan.count
            })

            let order_x = new Array(31).fill(0)
            d.orders_chart.forEach(order=>{
                let c= moment(order._id,"YYYY-MM-DD").format('jYYYY-jMM-jDD')
                console.log(c)
                let day = c.split("-")[2]
                order_x[parseInt(day)-1] = order.count
            })

            let subs_x = new Array(31).fill(0)
            d.subs_chart.forEach(sub=>{
                let c= moment(sub._id,"YYYY-MM-DD").format('jYYYY-jMM-jDD')
                console.log(c)
                let day = c.split("-")[2]
                subs_x[parseInt(day)-1] = sub.count
            })


            let book_x = new Array(31).fill(0)
            d.books_chart.forEach(book=>{
                let c= moment(book._id,"YYYY-MM-DD").format('jYYYY-jMM-jDD')
                console.log(c)
                let day = c.split("-")[2]
                book_x[parseInt(day)-1] = book.count
            })


            let visit_x = new Array(31).fill(0)
            d.visits_chart.forEach(visit=>{
                let c= moment(visit._id,"YYYY-MM-DD").format('jYYYY-jMM-jDD')
                console.log(c)
                let day = c.split("-")[2]
                visit_x[parseInt(day)-1] = visit.count
            })

            const trainer_data = await getTrainerProfileApi()
            let tr = trainer_data.data.data
            set_trainer(tr)


            let allChartData= [{
                name: 'بازدید',
                data:  visit_x
            }]

            if(tr?.services?.contains("plan")){
                allChartData.push({
                    name: 'خرید پکیج',
                    data:  plan_x
                })
            }

            if(tr?.services?.contains("shop")){
                allChartData.push(  {
                    name: 'سفارش',
                    data:  order_x
                })
            }

            if(tr?.services?.contains("booking")){
                allChartData.push( {
                    name: 'رزرو نوبت',
                    data:  book_x
                })
            }

            if(tr?.services?.contains("subscription")){
                allChartData.push({
                    name: 'اشتراک',
                    data:  subs_x
                },)
            }


            set_series(allChartData)

        }catch (e) {
            console.log(e)
        }
    }


    const [selectedMonth,set_selectedMonth] = useState("01")
    const [selectedYear,set_selectedYear] = useState("1403")


    //Month Drawer
    const [visibleMonthBottomDrawer,set_visibleMonthBottomDrawer] = useState(false)

    const showMonthBottomDrawer = ()=>{
        set_visibleMonthBottomDrawer(!visibleMonthBottomDrawer)
    }

    const onMonthBottomDrawerClose = () => {
        set_visibleMonthBottomDrawer(false);
    };

    const handleClickMonthBottomDrawer = (v)=>{
        set_selectedMonth(v)
        set_visibleMonthBottomDrawer(false)

        if(v==="all"){
            let nextYear = parseInt(selectedYear)+1
            let startTime = selectedYear+"-01-01"
            let endTime = nextYear+"-01-01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')

            getReportPage()
        }else {

            if(v==='12'){
                let next_year = parseInt(selectedYear)+1
                let startTime = selectedYear+"-12-01"
                let endTime = next_year+"-01-01"
                startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                getReportPage()
            }else {
                let month = parseInt(v);
                let nextMonth = parseInt(v)+1;

                if(month<10)
                    month = "0"+month

                if(nextMonth<10)
                    nextMonth = "0"+nextMonth

                let startTime = selectedYear+"-"+month+"-"+"01"
                let endTime = selectedYear+"-"+nextMonth+"-"+"01"
                startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                getReportPage()
            }


        }

    }

    //Year Drawer
    const [visibleYearBottomDrawer,set_visibleYearBottomDrawer] = useState(false)

    const showYearBottomDrawer = ()=>{
        set_visibleYearBottomDrawer(!visibleYearBottomDrawer)
    }

    const onYearBottomDrawerClose = () => {
        set_visibleYearBottomDrawer(false);
    };

    const handleClickYearBottomDrawer = (v)=>{
        set_selectedYear(v)
        set_visibleYearBottomDrawer(false)

        if(selectedMonth==="all"){
            let nextYear = parseInt(v)+1
            let startTime = v+"-01-01"
            let endTime = nextYear+"-01-01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            getReportPage()
        }else {

            if(selectedMonth==="12"){
                let nextYear = parseInt(v)+1
                let startTime = v+"-12-01"
                let endTime = nextYear+"-01-01"
                startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                getReportPage()
            }else {
                let month = parseInt(selectedMonth);
                let nextMonth = parseInt(selectedMonth)+1;
                if(month<10)
                    month = "0"+month

                if(nextMonth<10)
                    nextMonth = "0"+nextMonth

                let startTime = v+"-"+month+"-01"
                let endTime = v+"-"+nextMonth+"-01"
                startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
                getReportPage()
            }



        }

    }


    return(
        <div>

            <DumbbellHeader title={"استپ من"}/>

            <div className="my-step-card">
                <div className="my-step-card-cheap">
                    <img src={CheapIcon} alt=""/>
                </div>

                <div className="my-step-card-credit">
                    <h6>{numberWithCommas(trainer?.point)} </h6>
                    <span>تومان</span>
                </div>

                {trainer?
                    <div className="my-step-card-info">
                        <span>{moment(trainer?.createdAt).format("jYYYY/jMM")}</span>
                        <span>{trainer?.first_name+" "+trainer?.last_name}</span>
                    </div>:null
                }



            </div>

            <div className="my-step-title">
                <span></span>
                <span>کارت های جایزه</span>
            </div>

            <div className="my-step-reward">

                {rewards?.map(r=>{
                    return(
                        <div  onClick={e=>{
                            handleClickOnReward(r)
                        }} className={`my-step-reward-item clickable ${r.grade}-reward`}>
                            <img src={GiftIcon} alt=""/>
                            <span>{numberWithCommas(r.amount)}</span>
                            <span className="unit-reward">تومان</span>
                        </div>
                    )
                })}


            </div>


            <br/>
            <div className="my-step-title">
                <span></span>
                <span>درخواست ها</span>
            </div>

            <div className="quick-access">

                {trainer?.services?.contains("plan") ?
                <div className="quick-access-item clickable" onClick={e=>{
                    history.push("/my-requests",{filter:"new"})
                }}>
                    <img src={packageIcon} alt=""/>
                    <h6>پکیج ها</h6>
                    {newPlanNotif>0?<span>{newPlanNotif}</span>:null}
                </div>:null}

                {trainer?.services?.contains("shop") ?
                <div className="quick-access-item clickable" onClick={e=>{
                    history.push("/my-requests",{filter:"order"})
                }}>
                    <img src={OrderIcon} alt=""/>
                    <h6>سفارشات</h6>
                    {newOrderNotif>0?<span>{newOrderNotif}</span>:null}
                </div>:null}

                {trainer?.services?.contains("subscription") ?
                    <div className="quick-access-item clickable" onClick={e=>{
                        history.push("/my-requests",{filter:"subscription"})
                    }}>
                        <img src={SubsIcon} alt=""/>
                        <h6>اشتراک</h6>
                        {newSubsNotif>0?<span>{newSubsNotif}</span>:null}
                    </div>:null}



                {trainer?.services?.contains("booking") ?
                <div className="quick-access-item clickable" onClick={e=>{
                    history.push("/my-requests",{filter:"turn"})
                }}>
                    <img src={bookingIcon} alt=""/>
                    <h6>نوبت ها</h6>
                    {newTurnNotif>0?<span>{newTurnNotif}</span>:null}
                </div>:null}


                {/*<div className="quick-access-item clickable" onClick={e=>{*/}
                {/*    history.push("/my-trainee",{filter:"unSeen"})*/}
                {/*}}>*/}
                {/*    <img src={MessageIcon} alt=""/>*/}
                {/*    <h6>پیام ها</h6>*/}
                {/*    {newMessageNotif>0?<span>{newMessageNotif}</span>:null}*/}
                {/*</div>*/}
            </div>


            <br/>
            <div className="my-step-title">
                <div className="report-filter">
                    <div className="report-filter-item clickable" onClick={showYearBottomDrawer}>
                        <span>{selectedYear}</span>
                        <img src={caretDownIcon} alt=""/>
                    </div>
                    <div className="report-filter-item clickable" onClick={showMonthBottomDrawer}>
                        <span>{convertMonthNumberToName(selectedMonth)}</span>
                        <img src={caretDownIcon} alt=""/>
                    </div>
                </div>
                <span>گزارش صفحه</span>
            </div>

            <div className="my-step-page-report-statistic">

                {trainer?.services?.contains("booking") ?
                <div className="my-step-page-report-statistic-item">
                    <span>{buyBook}</span>
                    <h6>رزرو</h6>
                </div>:null}

                {trainer?.services?.contains("plan") ?
                <div className="my-step-page-report-statistic-item">
                    <span>{buyPlan}</span>
                    <h6>پکیج</h6>
                </div>:null}

                {trainer?.services?.contains("shop") ?
                <div className="my-step-page-report-statistic-item">
                    <span>{buyOrder}</span>
                    <h6>سفارش</h6>
                </div>:null}


                {trainer?.services?.contains("subscription") ?
                <div className="my-step-page-report-statistic-item">
                    <span>{buySubs}</span>
                    <h6>اشتراک</h6>
                </div>:null}

                <div className="my-step-page-report-statistic-item">
                    <span>{visit}</span>
                    <h6>بازدید</h6>
                </div>
            </div>
            <div className="my-step-page-report-chart">
                <ReactApexCharts options={options} series={series} type="area" height={200} />
            </div>


            <div className="my-step-title">
                <span></span>
                <span>میزان بدهی شما</span>
            </div>

                <div className="my-step-debt">
                    <Spin spinning={debtSpin}>
                        <div className="my-step-debt-row">
                            <div className="my-step-debt-btn force-debt-btn clickable" onClick={e=>{
                                checkoutForceDebt(force_debt)
                            }}>
                                <span>تسویه ضروری</span>
                            </div>
                            <div className="my-step-debt-amount">
                                <span>تومان</span>
                                <h6>{numberWithCommas(force_debt)}</h6>
                            </div>
                        </div>

                        <div className="my-step-debt-row">
                            <div className="my-step-debt-btn debt-btn clickable" onClick={e=>{
                                checkoutDebt(debt)
                            }}>
                                <span>تسویه جاری</span>
                            </div>
                            <div className="my-step-debt-amount">
                                <span>تومان</span>
                                <h6>{numberWithCommas(debt)}</h6>
                            </div>
                        </div>

                        {/*{debt>0?*/}
                        {/*    <div className="my-step-debt-checkout-btn" onClick={checkoutDebt}>*/}
                        {/*        <span className="clickable">تسویه بدهی</span>*/}
                        {/*    </div>*/}
                        {/*    :null*/}
                        {/*}*/}

                        <div className="my-step-debt-checkout-description">
                            <p>با پرداخت به موقع بدهی خود امتیاز خود را بالاتر برده و خدمات بهتری از استپ دریافت نمایید</p>
                        </div>


                    </Spin>
                </div>

            <div className="my-step-learn clickable">
                <span>آموزش تصویری کار با استپ</span>
            </div>

            <div className="my-step-support clickable">
                <span>پشتیبانی استپ</span>
            </div>
            <div className="my-step-logout clickable" onClick={logout}>
                <span>خروج از استپ</span>
            </div>

            <Drawer height={200}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#ffffff",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onRewardBottomDrawerClose} visible={visibleRewardBottomDrawer}>

                <Spin spinning={buySpin}>
                    <div className="reward-buy-drawer">
                        <div className="reward-buy-drawer-message">
                            <span>آیا شما مایل به خرید این کارت می باشید؟ </span>
                        </div>

                        <div className="reward-buy-drawer-card">
                            <div   className={`my-step-reward-item width-100 clickable ${selectedReward?.grade}-reward`}>
                                <img src={GiftIcon} alt=""/>
                                <span>{numberWithCommas(selectedReward?.amount)}</span>
                                <span className="unit-reward">تومان</span>
                            </div>
                        </div>
                        <div className="reward-buy-drawer-btns">
                            <span className="reward-buy-drawer-btn deny clickable" onClick={handleCancelBuy}>انصراف</span>
                            <span className="reward-buy-drawer-btn accept clickable" onClick={handleAcceptBuy}>دریافت جایزه</span>
                        </div>
                    </div>
                </Spin>

            </Drawer>



            <Drawer height={300}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onYearBottomDrawerClose} visible={visibleYearBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">

                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1403")
                    }}>
                        <span className="label">1403</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1404")
                    }}>
                        <span className="label">1404</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1405")
                    }}>
                        <span className="label">1405</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1406")
                    }}>
                        <span className="label">1406</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1407")
                    }}>
                        <span className="label">1407</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1408")
                    }}>
                        <span className="label">1408</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1409")
                    }}>
                        <span className="label">1409</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1410")
                    }}>
                        <span className="label">1410</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1411")
                    }}>
                        <span className="label">1411</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1412")
                    }}>
                        <span className="label">1412</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1413")
                    }}>
                        <span className="label">1413</span>
                    </div>

                </div>
            </Drawer>

            <Drawer height={300}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onMonthBottomDrawerClose} visible={visibleMonthBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">
                    {/*<div className="bottom-drawer-item clickable" onClick={e=>{*/}
                    {/*    handleClickMonthBottomDrawer("all")*/}
                    {/*}}>*/}
                    {/*    <span className="label">همه ماها</span>*/}
                    {/*</div>*/}
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("01")
                    }}>
                        <span className="label">فرودردین</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("02")
                    }}>
                        <span className="label">اردیبهشت</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("03")
                    }}>
                        <span className="label">خرداد</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("04")
                    }}>
                        <span className="label">تیر</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("05")
                    }}>
                        <span className="label">مرداد</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("06")
                    }}>
                        <span className="label">شهریور</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("07")
                    }}>
                        <span className="label">مهر</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("08")
                    }}>
                        <span className="label">آبان</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("09")
                    }}>
                        <span className="label">آذر</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("10")
                    }}>
                        <span className="label">دی</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("11")
                    }}>
                        <span className="label">بهمن</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("12")
                    }}>
                        <span className="label">اسفند</span>
                    </div>

                </div>
            </Drawer>

        </div>
    )
}

export default MyStep
